<template>
    <v-card :outlined="outlined" flat class="gst-ticket-offer-small-card gst-ticket-offer-small-card--variant-2">
        <v-layout align-center>
            <v-flex v-if="ticket.section"
                class="px-2"
                :class="{
                    'xs5': !hideSeats,
                    'xs10': hideSeats,
                }">
                <VClamp
                    autoresize
                    :max-lines="1"
                    class="hyphens clamp gst-ticket-offer-small-card__seat">
                    {{ ticket | seatLocation }}
                </VClamp>
                <h5 class="gst-ticket-offer-small-card__title pt-1">
                    {{ $t( '_common:entities.seat.section' ) }}
                </h5>
            </v-flex>
            <v-flex
                v-if="!hideSeats"
                class="px-3"
                :class="{
                    'xs5': ticket.section,
                    'xs10': !ticket.section,
                }">
                <v-flex>
                    <VClamp
                        autoresize
                        :max-lines="1"
                        class="hyphens clamp gst-ticket-offer-small-card__seat pt-1">
                        {{ seatRangeTitle }}
                    </VClamp>
                    <h5 v-if="seatRangeStartEnd.length <= 1" class="gst-ticket-offer-small-card__title pt-1">
                        {{ $t( '_common:entities.seat.seat', { count: quantity } ) }}
                    </h5>
                    <span v-else class="d-inline-flex">
                        <h5 class="gst-ticket-offer-small-card__title pt-1">
                            {{ $t( '_common:entities.seat.seatRange' ) }}
                        </h5>
                        <SeatRangeTooltip />
                    </span>
                </v-flex>
            </v-flex>
        </v-layout>
    </v-card>
</template>
<script>
    import VClamp from 'vue-clamp';
    import SeatRangeTooltip from '@core/shared/components/misc/SeatRangeTooltip.vue';
    import TicketCardMixin from './TicketCardMixin';

    export default {
        name: 'TicketCardVariantTwo',
        components: {
            VClamp,
            SeatRangeTooltip
        },
        mixins: [ TicketCardMixin ],
        i18nOptions: {
            keyPrefix: 'components.ticket.ticketOfferCardSmall',
            namespaces: 'shared'
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";

.gst-ticket-offer-small-card {
    position: relative;
    background-color: transparent;
    border-radius: border-radius( 'm' ) !important;
    max-width: 100%;
    border-color: theme-color( 'quinary' ) !important;

    .gst-ticket-offer-small-card__title {
        line-height: 14px;
        color: theme-color( 'tertiary' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'regular' );
        letter-spacing: -0.25px;
        text-transform: capitalize;
    }

    .gst-ticket-offer-small-card__seat {
        color: theme-color( 'quaternary' );
        font-size: font-size( 'xxxl' );
        font-weight: font-weight( 'medium' );
    }
}
</style>
<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-ticket-offer-small-card--variant-2 {
    .gst-ticket-card-variant-two_tooltip {
        margin-top: theme-spacing( n1 );
        margin-left: theme-spacing( 1 );
    }
}
</style>