<template>
    <div class="gst-order-summary px-4">
        <div class="gst-order-summary__group-item d-flex flex-row px-0 py-4 py-md-5">
            <div class="flex xs8 u-text-transform-by-language">
                {{ $t("_common:terms.quantity") }}
            </div>
            <div class="flex xs4 text-right gst-order-summary__total-quantity">
                {{ totalQuantity }}
            </div>
        </div>
        <div
            v-for="(offer, index ) in offersWithFees"
            :key="index"
            class="gst-order-summary__group-item px-0 pt-0 pb-4 pt-md-1 pb-md-6">
            <div
                v-for="(fee, index2 ) in offer.fees"
                :key="index2"
                class="gst-order-summary__item d-flex flex-row u-width-100 px-0 pt-4">
                <div
                    class="flex xs8 u-text-transform-by-language"
                    :class="{
                        'gst-order-summary__label-1': fee.isBase,
                        'gst-order-summary__label-2': !fee.isBase
                    }">
                    {{ fee.label }}
                    <div v-if="fee.isBase && shouldShowOriginalPrice"
                        class="gst-order-summary__label-4">
                        {{ `(${$t("_common:terms.originalPrice")}: ${formatOriginalResalePrice(originalPrice)} ${$t("_common:terms.perTicket")})` }}
                    </div>
                </div>
                <div
                    class="gst-order-summary__label-2 flex-shrink-0 text-left font-weight-medium">
                    x{{ offer.quantity }}
                </div>
                <div
                    :class="{
                        'gst-order-summary__label-3': fee.isBase,
                        'gst-order-summary__label-2': !fee.isBase,
                    }"
                    class="flex-grow-1 text-right font-weight-medium">
                    {{ ( fee.price + fee.tax ) | currencyFull( currency ) }}
                </div>
            </div>
        </div>
        <div
            v-for="(orderFee, index ) in orderWithFees"
            :key="'order' + index"
            class="gst-order-summary__group-item d-flex px-0 py-4 py-md-5"
            d-flex
            flex-column>
            <div
                class="flex xs6 u-text-transform-by-language">
                {{ orderFee.label }}
            </div>
            <div xs6 class="gst-order-summary__label-3 flex xs6 text-right">
                {{ ( orderFee.price + orderFee.tax ) | currencyFull( currency ) }}
            </div>
        </div>
        <div v-if="showShippingFeesSection" class="gst-order-summary__group-item px-0 py-4 py-md-5">
            <div class="d-flex">
                <div class="flex xs8 gst-order-summary__label-1 u-text-transform-by-language">
                    {{ $t("labels.shipping") }}
                </div>
                <div class="flex xs4 text-right font-weight-medium">
                    {{ shippingFee }}
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="flex xs8 gst-order-summary__label-2 u-text-transform-by-language">
                    {{ $t("labels.shippingTaxes") }}
                </div>
                <div class="flex xs4 text-right gst-order-summary__label-2">
                    {{ shippingTax }}
                </div>
            </div>
        </div>
        <div v-if="isLoyaltyTypeAllowRedeem" class="gst-order-summary__group-item px-0 py-4 py-md-5 d-flex align-center" :class="{'gst-disabled--text': !paymentModel.points }">
            <div class="flex xs6 gst-order-summary__label-1 u-text-transform-by-language">
                {{ isOrderConfirmation ? $t("labels.bonusPointsRedeemPast") : $t("labels.bonusPointsRedeem") }}
            </div>
            <div class="flex xs6 text-right gst-order-summary__total-price" data-test-id="totalPoints">
                {{ $t("_common:terms.bonusPointWithCount", { count: paymentModel.points, formattedCount: bonusPointsFormatted } ) }}
            </div>
        </div>
        <div class="gst-order-summary__group-item px-0 py-4 py-md-5 d-flex flex-row align-start align-md-center">
            <div class="flex xs6">
                <div class="gst-order-summary__label-1 u-text-transform-by-language">
                    {{ isOrderConfirmation ? $t("labels.finalTotalPast") : $t("labels.finalTotal") }}
                </div>
                <div class="gst-order-summary__label-2">
                    {{ $t("labels.includingTaxes") }}
                </div>
            </div>
            <div class="flex xs6 text-right gst-order-summary__total-price" data-test-id="totalPrice">
                {{ paymentModel.amount | currencyFull( currency ) }}
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { cleanAndTranslate as feeUtilsCleanAndTranslate } from '@core/utils/feeUtils';
    import { sortCompare } from '@core/utils/sortCompareUtils';
    import currencyShort from '@core/filters/currencyShort';
    import SessionStorageService from '@core/services/SessionStorageService';
    import { isResale as ticketUtilsIsResale } from '@core/utils/ticketUtils';
    import {
        findOneNonElectronicTicketItem as shippingOptionsUtilsFindOneNonElectronicTicketItem,
        findOneItemWithFees as shippingOptionsUtilsFindOneItemWithFees
    } from '@core/utils/shippingOptionsUtils';
    import { getWithoutDelivery as getOrderChargesWithoutDelivery } from '@core/utils/feeUtils';

    export default {
        name: 'OrderSummary',
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theCheckoutWizard._components.containerItemPriceDetailCard'
        },
        props: {
            cartCollection: {
                type: Object,
                required: true
            },
            order: {
                type: Object,
                required: true
            },
            paymentModel: {
                type: Object,
                required: true
            },
            shippingModel: {
                type: Object,
                required: true
            },
            showShippingFeesSectionEmpty: {
                type: Boolean,
                default: false
            },
            isOrderConfirmation: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isAllInclusivePricing( ) {
                return SessionStorageService.eventIsAllInclusivePricing.get( );
            },
            ...mapGetters ( 'appTenant', {
                'isLoyaltyTypeAllowRedeem': 'isLoyaltyTypeAllowRedeem'
            } ),
            offersWithFees( ) {
                if ( !this.offers.length ) {
                    return [];
                }

                return this.offers
                    .map( ( { name, quantity, charges } ) => {
                        return {
                            name,
                            quantity,
                            fees: feeUtilsCleanAndTranslate( charges, name ).sort( ( a, b ) => sortCompare( a.order, b.order ) )
                        };
                    } );
            },
            offers( ) {
                return this.cartCollection.offers;
            },
            orderWithFees( ) {
                if ( !this.order || !this.order.charges ) {
                    return [];
                }

                return feeUtilsCleanAndTranslate( this.orderChargesWithoutDelivery );
            },
            orderChargesWithoutDelivery( ) {
                return getOrderChargesWithoutDelivery( this.order.charges );
            },
            totalQuantity( ) {
                return this.offers.reduce( ( acc, { quantity } ) => {
                    return acc + quantity;
                },  0 );
            },
            currency( ) {
                return this.order.currency;
            },
            showShippingFeesSection( ) {
                const { options } = this.shippingModel;

                return shippingOptionsUtilsFindOneNonElectronicTicketItem( options )
                    || shippingOptionsUtilsFindOneItemWithFees( options );
            },
            shippingFee() {
                return ( this.paymentModel.shippingFee && !this.showShippingFeesSectionEmpty ) ? this.$options.filters.currencyFull( this.paymentModel.shippingFee, this.currency ) : '-';
            },
            shippingTax() {
                return ( this.paymentModel.shippingTax && !this.showShippingFeesSectionEmpty ) ? this.$options.filters.currencyFull( this.paymentModel.shippingFee, this.currency ) : '-';
            },
            ticket() {
                /**
                 * TODO ionut - 2024-03-05
                 * This is a hack. This component should receive an wrapper prop with prices
                 * and product information from cart or order
                 * In this moment we are taking data from two props. 
                 */
                const ticketOrder = this.order?.items?.[0].ticket;
                const ticketCart =  this.cartCollection?.items?.[0];
                return ticketCart || ticketOrder;
            },
            shouldShowOriginalPrice( ) {
                /**
                 * TODO ionut - 2024-03-13 ( SAME THING )
                 * This is a hack. This component should receive an wrapper prop with prices
                 * and product information from cart or order
                 * In this moment we are taking data from two props. 
                 */
                const stateCode = this.cartCollection.info?.venueStateCode || this.order.items?.[ 0 ]?.event?.address?.stateCode;
                const countryCode = this.cartCollection.info?.venueCountryCode || this.order.items?.[ 0 ]?.event?.address?.countryCode;


                return ticketUtilsIsResale( this.ticket.inventory )
                    && countryCode === 'US' && [ 'NY', 'TN' ].includes( stateCode )
                    && this.isAllInclusivePricing;
            },
            originalPrice() {
                return this.ticket.averageOriginalPrice;
            },
            bonusPointsFormatted( ) {
                return this.$options.filters.bonusPoints( this.paymentModel.points  );
            }
        },
        methods: {
            formatOriginalResalePrice( originalPrice ) {
                return currencyShort( originalPrice, this.currency );        
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-order-summary {
        border: 1px solid theme-color( 'quinary' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'regular' );
        border-radius: border-radius( 'm' );

        .gst-order-summary__group-item {
            border-top: 1px solid theme-color( 'quinary' );

            &:first-of-type {
                border: none;
            }

            .gst-order-summary__item {
                min-height: 20px;
            }
        }

        .gst-order-summary__group-item.gst-disabled--text {
            opacity: 0.4;
        }

        .gst-order-summary__label-1 {
            color: theme-color( 'quaternary' );
            letter-spacing: -0.41px;
        }

        .gst-order-summary__label-2 {
            color: theme-color( 'tertiary' );
            letter-spacing: -0.41px;
        }

        .gst-order-summary__label-3 {
            color: theme-color( 'quaternary' );
            font-weight: font-weight( 'medium' );
            letter-spacing: -0.41px;
        }

        .gst-order-summary__label-4 {
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xs' );
            font-weight: font-weight( 'regular' );
            letter-spacing: -0.41px;
        }

        .gst-order-summary__total-price {
            color: theme-color( 'quaternary' );
            font-size: font-size( 'xxl' );
            font-weight: font-weight( 'medium' );
            letter-spacing: -0.41px;
        }
    }
</style>
