import {
    getSetting as getSettingStorageUtils,
    saveSetting as saveSettingStorageUtils
} from '@core/utils/storageSessionUtils';

const LOCAL_STORAGE_KEYS = {
    EVENT_IS_ALL_INCLUSIVE_PRICING: 'gstEventIsAllInclusivePricing',
};

export default {
    eventIsAllInclusivePricing: {
        get( ) {
            return !!getSettingStorageUtils( LOCAL_STORAGE_KEYS.EVENT_IS_ALL_INCLUSIVE_PRICING );
        },
        set( isAllInclusivePricing ) {
            saveSettingStorageUtils( LOCAL_STORAGE_KEYS.EVENT_IS_ALL_INCLUSIVE_PRICING, isAllInclusivePricing );
        }
    }
};
