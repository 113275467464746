
<template>
    <v-flex
        d-flex
        align-center
        class="gst-ticket-location-icon">
        <BaseIcon class="mr-2 flex-shrink-0" symbol-id="icons--location" />
        <VenueInfo :item="venue" :clampable="false" />
    </v-flex>
</template>
<script>
    import VenueInfo from '@core/shared/components/containerInfo/VenueInfo.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'TicketLocationIcon',
        components: {
            VenueInfo,
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.ticket.ticketOfferTypeIcon'
        },
        props: {
            venue: {
                type: Object,
                default: () => ( { name: '', city: '', street: '', stateCode: '', countryCode: '' } )
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";

    .gst-ticket-location-icon {
        font-weight: font-weight( 'medium' );

        svg {
            opacity: 0.6;

            ::v-deep .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }

        fill: theme-color( 'tertiary' );
    }
</style>
