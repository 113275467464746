<template>
    <BaseTooltip
        :text="$t( '_common:entities.seat.seatRangeInfo' )"
        :open-on-click="!$vuetify.breakpoint.mdAndUp"
        :open-on-hover="$vuetify.breakpoint.mdAndUp"
        class-content="gst-seat-range__tooltip gst-base-tooltip">
        <v-btn class="gst-seat-range__tooltip-btn gst-ticket-card-variant-two_tooltip gst-ticket-card-variant-three_tooltip" icon small :ripple="false">
            <BaseIcon symbol-id="icons--help" class="gst-seat-range__tooltip-icon" />
        </v-btn>
    </BaseTooltip>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    
    export default {
        name: 'SeatRangeTooltip',
        components: {
            BaseTooltip,
            BaseIcon
        },
        i18nOptions: {
            keyPrefix: 'components.ticket.ticketOfferCardSmall',
            namespaces: 'shared'
        }
    };
</script>
<style lang="scss">
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-seat-range__tooltip-icon {
        outline: none;

        .gst-svg-icon {
            fill: theme-color( 'senary' );
            transition: all 0.5s ease;
        }
    }

    .gst-seat-range__tooltip-icon:hover {
        .gst-svg-icon {
            fill: theme-color( 'primary' );
        }
    }

    .gst-seat-range__tooltip-btn::before {
        display: none;
    }

    .gst-seat-range__tooltip.v-tooltip__content {
        line-height: 1.3;
        padding: theme-spacing( 2 ) !important;
        background: theme-color( 'tertiary' ) !important;
        font-size: font-size( "xxs" );
    }
</style>