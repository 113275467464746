
<template>
    <v-flex
        d-flex
        align-center
        class="gst-ticket-date-icon">
        <BaseIcon class="mr-2" symbol-id="icons--date" />
        {{ date }}
    </v-flex>
</template>
<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'TicketDateIcon',
        components: {
            BaseIcon
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.ticket.ticketOfferTypeIcon'
        },
        props: {
            startDateTime: {
                type: Date,
                required: true
            },
            endDateTime: {
                type: Date,
                default: null
            },
            hasStartTime: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            date( ) {
                return this.$options.filters.datePeriod( this.startDateTime, this.endDateTime, { hasStartTime: this.hasStartTime } );
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";

    .gst-ticket-date-icon {
        font-weight: font-weight( 'medium' );

        svg {
            opacity: 0.6;

            ::v-deep .gst-svg-icon {
                fill: theme-color( 'tertiary' );
            }
        }

        fill: theme-color( 'tertiary' );
    }

</style>
