export default {
    props: {
        ticket: {
            type: Object,
            required: true
        },
        quantity: {
            type: Number,
            default: 0,
        },
        outlined: {
            type: Boolean,
            default: true
        },
        event: {
            type: Object,
            required: true
        },
        hideSeats: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        seats( ) {
            const { ticket } = this;

            return ticket.seats || [ ];
        },
        seatRangeStartEnd() {
            const { ticket } = this;
            const seatNumbers = [ ticket.startSeatNumber, ticket.endSeatNumber ].filter( Boolean ).map( String );

            if ( seatNumbers.includes( '*' ) ) {
                return seatNumbers;
            }
            
            return [ ...new Set( seatNumbers ) ];
        },
        seatRangeTitle( ) {
            return this.seatRangeStartEnd.join( ' - ' ) || this.$t( '_common:entities.seat.noSeats' );
        },
        seatDetails() {
            if( this.hideSeats && this.quantity > 1 ) {
                return this.$t( '_common:entities.seat.allSeats' );
            }
        }
    }
};